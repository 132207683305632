import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const NothingOptions = () => {
  return (
    <Options id="buy" aria-label="Purchase Options">
      <OptionCard
        whileHover={{ scale: 1.05 }}
        role="article"
        aria-labelledby="one-time-title"
        aria-describedby="one-time-price"
      >
        <Title id="one-time-title">One-Time Nothing</Title>
        <Price id="one-time-price">$4.99</Price>
        <PurchaseButton
          href="https://buy.stripe.com/4gw7sP9bX54j8dabIN"
          aria-label="Buy One-Time Nothing for $4.99"
        >
          Buy Now
        </PurchaseButton>
      </OptionCard>
      <OptionCard
        whileHover={{ scale: 1.05 }}
        role="article"
        aria-labelledby="monthly-title"
        aria-describedby="monthly-price"
      >
        <Title id="monthly-title">Monthly Nothing</Title>
        <Price id="monthly-price">$4.99/month</Price>
        <PurchaseButton
          href="https://buy.stripe.com/4gw4gDewh8gvdxuaEI"
          aria-label="Subscribe to Monthly Nothing for $4.99 per month"
        >
          Subscribe Now
        </PurchaseButton>
      </OptionCard>
    </Options>
  );
};

// Styled Components

const Options = styled.section`
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 60px 20px;
  background: #f5f5f5;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const OptionCard = styled(motion.div)`
  background: #f5f5f5;
  color: #333333;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
  width: 300px;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Price = styled.p`
  font-size: 28px;
  font-weight: bold;
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const PurchaseButton = styled(motion.a)`
  display: inline-block;
  padding: 15px 30px;
  background: #9a9a5f;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 12px 25px;
  }
`;

export default NothingOptions;
