import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import NothingOptions from './components/NothingOptions';
import FAQ from './components/FAQ';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Description from './components/Description';
import { Routes, Route } from 'react-router-dom';
import './index.css';

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <HeroSection />
            <Description />
            <NothingOptions />
            <FAQ />
            <Testimonials />
            </>
        } />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/termsOfService" element={<TermsOfService />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
