import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Testimonials = () => {
  return (
    <TestimonialsSection id="testimonials">
      <Title>What People Are Saying</Title>
      <TestimonialGrid>
        <TestimonialCard
          whileHover={{ scale: 1.03 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          role="article"  // Semantic role for screen readers
          aria-labelledby="testimonial1-quote"
          aria-describedby="testimonial1-author"
        >
          <Quote id="testimonial1-quote">
            "I've never felt more fulfilled by getting absolutely nothing! It was quick, easy, and I got a good laugh out of it too!"
          </Quote>
          <Author id="testimonial1-author">- A Happy Customer</Author>
        </TestimonialCard>
        <TestimonialCard
          whileHover={{ scale: 1.03 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          role="article"
          aria-labelledby="testimonial2-quote"
          aria-describedby="testimonial2-author"
        >
          <Quote id="testimonial2-quote">
            "I needed to meet the monthly transaction requirement on my card, and buying 'nothing' was the perfect solution."
          </Quote>
          <Author id="testimonial2-author">- A Practical Purchaser</Author>
        </TestimonialCard>
        <TestimonialCard
          whileHover={{ scale: 1.03 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
          role="article"
          aria-labelledby="testimonial3-quote"
          aria-describedby="testimonial3-author"
        >
          <Quote id="testimonial3-quote">
            "I bought 'nothing' as a gag gift for a friend, but it ended up being the last transaction I needed to earn my cashback rewards. Who knew nothing could be so rewarding?"
          </Quote>
          <Author id="testimonial3-author">- A Rewarded Buyer</Author>
        </TestimonialCard>
        <TestimonialCard
          whileHover={{ scale: 1.03 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
          role="article"
          aria-labelledby="testimonial4-quote"
          aria-describedby="testimonial4-author"
        >
          <Quote id="testimonial4-quote">
            "The best purchase I've never made!"
          </Quote>
          <Author id="testimonial4-author">- A Fan of Nothing</Author>
        </TestimonialCard>
        <TestimonialCard
          whileHover={{ scale: 1.03 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
          role="article"
          aria-labelledby="testimonial5-quote"
          aria-describedby="testimonial5-author"
        >
          <Quote id="testimonial5-quote">
            "🤷"
          </Quote>
          <Author id="testimonial5-author">- A Character from the Emoji Movie</Author>
        </TestimonialCard>
      </TestimonialGrid>
    </TestimonialsSection>
  );
};

// Styled Components

const TestimonialsSection = styled.section`
  background: #F5F5F5; /* Light crème background */
  color: #333333; /* Dark charcoal text color */
  padding: 60px 20px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 32px;
  margin-bottom: 40px;
`;

const TestimonialGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
`;

const TestimonialCard = styled(motion.div)`
  background: #FFFFFF; /* Creamy white for card background */
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
`;

const Quote = styled.p`
  font-size: 18px;
  font-style: italic;
  margin-bottom: 20px;
`;

const Author = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #9A9A5F; /* Muted olive for emphasis */
`;

export default Testimonials;
